<script lang="ts" setup>
import {toRefs} from 'vue';
import {OrderStatus} from '../interfaces';
import {useSettings} from '../settings';
import {useTranslates} from '../translates';
const {colorTheme, order, logo} = toRefs(useSettings());
const S = useTranslates();
</script>
<template>
    <div class="row" v-if="order">
        <div class="col-left">
            <span>
                {{ S.orderFrom }}
                {{ order.title ? S.from : '' }}
                <span class="font-medium">{{ order.title }}</span>
                {{ S.is }}
            </span>
            <span :class="{warn: order.state == OrderStatus.Cancelled, error: !order.state}" class="header-status">
                {{ S.state[order.state] }}
            </span>
        </div>
        <div v-if="logo" class="col-right">
            <img style="width: 100px; height: 80px; object-fit: contain" :src="logo" />
        </div>
    </div>
</template>
<style lang="less" scoped>
@import '../assets/variables.less';
.row {
    display: flex;
    margin-bottom: @margin;
    :deep(.ant-image) {
        border: 1px solid black;
    }
    font-size: 20px;
    .col-left {
        flex: 1;
    }
    .col-right {
        flex: 0 1 80px;
    }

    .header-status {
        color: v-bind(colorTheme); //@successColor;
        &.warn {
            color: @warnColor;
        }
        &.error {
            color: @black;
        }
    }
}
</style>
