import {clone} from 'lodash';
import {reactive, Ref, ref} from 'vue';
import {Order, OrderStatus, OrderType} from './interfaces';
import {useTrackingService} from './tracking.service';
import {setOverrides} from './translates';

const trackingService = useTrackingService();
const isLocalDomain = document.location.port === '8085';

type TSettings = TWidgetSettings & {
    trackingId: string | null;
    order: Order | null;
    type?: boolean; // show/hide order type
    language?: string;
    logo?: string;
};

const defaultSettings: TSettings = {
    timeWindowSize: 7200, // in seconds
    trackingId: null,
    order: null,
    logo: '',
    language: navigator.language ? navigator.language.split('-')[0] : 'en',
    header: 'Some header',
    dynamicAttribute: '',
    stopNumber: false,
    useLogo: false,
    colorTheme: '#3fb148',
    footer: '',
    completionOptions: {
        useRating: true,
        showDeliveryGps: false,
        showSignature: false,
        cancellationReason: false
    },
    deliveryOptions: {
        useEta: false
    },
    orderFields: {
        reference: false,
        type: false,
        clientName: false,
        locationName: false,
        locationAddress: false,
        capacity1: false,
        capacity2: false,
        timeWindow: false,
        additionalAttributes: [],
        additionalAttributesEnabled: false
    },
    // not exist
    driverCurrentStop: false
};

const _settings: TSettings = reactive(clone(defaultSettings));

if (isLocalDomain) {
    const _order: Ref<Order> = ref({
        status: 'ALLOCATED',
        id: 'MD11234322',
        title: '',
        location: {
            postcode: 'B33 8TH',
            address: 'Holloway Head, Stechford, Birmingham B33 8TH',
            latitude: 52.5,
            longitude: -1.916667
        },
        weight: 12,
        volume: 0.5,
        customer: {
            name: 'John Smith',
            phone: '0121 123 4567'
        },
        date: '22.08.2022',
        type: OrderType.Delivery,
        trackingDetails: {
            // eta: '2023-02-01T09:03:59.887Z',
            stop: 11,
            currentStop: 10,
            totalStops: 24,
            driverLocation: {
                latitude: 52.3,
                longitude: -1.8
            },
            cancellationReason: 'Technical Difficulties'
        },
        plannedArrivalTime: '2023-07-04T16:06:26Z',
        state: OrderStatus.Scheduled,
        images: [
            {thumbnailUri: 'https://via.placeholder.com/100x100', fullUri: 'https://via.placeholder.com/800x800'},
            {thumbnailUri: 'https://via.placeholder.com/100x100', fullUri: 'https://via.placeholder.com/800x800'},
            {thumbnailUri: 'https://via.placeholder.com/100x100', fullUri: 'https://via.placeholder.com/800x800'},
            {thumbnailUri: 'https://via.placeholder.com/100x100', fullUri: 'https://via.placeholder.com/800x800'},
            {thumbnailUri: 'https://via.placeholder.com/100x100', fullUri: 'https://via.placeholder.com/800x800'}
        ],
        rating: {
            comment: '',
            rating: 0
        }
    } as Order);
    setTimeout(() => {
        _settings.order = _order.value;
    }, 1000);
} else {
    try {
        if (widgetSettings) {
            Object.assign(_settings, widgetSettings);
        }
    } catch (e) {}
    try {
        if (trackingId) {
            trackingService
                .getTrackingInfo(document.location.origin, trackingId)
                .then((o: Order | null) => {
                    if (o) {
                        _settings.trackingId = trackingId;
                        _settings.order = o;
                        trackingService.subscribeToTrackingInfoUpdate(
                            document.location.origin,
                            trackingId,
                            (updated: Order) => {
                                _settings.order = updated;
                            }
                        );
                    } else {
                        _settings.order = {
                            state: OrderStatus.NotFound
                        } as Order;
                    }
                })
                .catch((e: any) => {
                    _settings.order = {
                        state: OrderStatus.NotFound
                    } as Order;
                });
        }
    } catch (e) {
        _settings.order = {
            state: OrderStatus.NotFound
        } as Order;
    }

    try {
        _settings.logo = logo;
    } catch (e) {}
}

window.onmessage = (e: MessageEvent) => {
    if (e.data && e.data.type === 'settings') {
        _settings.order = e.data.order;
        Object.assign(_settings, e.data.settings);
        if (e.data.translates) {
            setOverrides(e.data.translates);
        }
    }
};

export function update(s: TSettings) {
    for (const key in s) {
        (_settings as any)[key] = (s as any)[key];
    }
}

export function useSettings() {
    return _settings;
}

export function setTitle(title: string) {
    document.title = title;
}

export async function setFavicon(color: string) {
    const canvas = document.createElement('canvas');
    canvas.width = 16;
    canvas.height = 16;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.save();
    ctx.strokeStyle = 'rgba(0,0,0,0)';
    ctx.miterLimit = 4;
    ctx.fillStyle = 'rgba(0,0,0,0)';
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.moveTo(1, 2);
    ctx.lineTo(9, 2);
    ctx.bezierCurveTo(9.55229, 2, 10, 2.44772, 10, 3);
    ctx.lineTo(10, 4);
    ctx.lineTo(12.5, 4);
    ctx.bezierCurveTo(12.8148, 4, 13.1111, 4.14819, 13.3, 4.4);
    ctx.lineTo(15.8, 7.73333);
    ctx.bezierCurveTo(15.9298, 7.90643, 16, 8.11696, 16, 8.33333);
    ctx.lineTo(16, 12);
    ctx.bezierCurveTo(16, 12.5523, 15.5523, 13, 15, 13);
    ctx.lineTo(14.95, 13);
    ctx.bezierCurveTo(14.7184, 14.1411, 13.7095, 15, 12.5, 15);
    ctx.bezierCurveTo(11.2905, 15, 10.2816, 14.1411, 10.05, 13);
    ctx.lineTo(9, 13);
    ctx.lineTo(5.94999, 13);
    ctx.bezierCurveTo(5.71836, 14.1411, 4.70948, 15, 3.5, 15);
    ctx.bezierCurveTo(2.29052, 15, 1.28164, 14.1411, 1.05001, 13);
    ctx.lineTo(1, 13);
    ctx.bezierCurveTo(0.447715, 13, 0, 12.5523, 0, 12);
    ctx.lineTo(0, 3);
    ctx.bezierCurveTo(0, 2.44772, 0.447715, 2, 1, 2);
    ctx.closePath();
    ctx.moveTo(5.50018, 11);
    ctx.bezierCurveTo(5.04408, 10.3928, 4.31791, 10, 3.5, 10);
    ctx.bezierCurveTo(2.9372, 10, 2.41783, 10.186, 2, 10.4998);
    ctx.lineTo(2, 4);
    ctx.lineTo(8, 4);
    ctx.lineTo(8, 11);
    ctx.lineTo(5.50018, 11);
    ctx.closePath();
    ctx.moveTo(14, 8.66667);
    ctx.lineTo(14, 10.4998);
    ctx.bezierCurveTo(13.5822, 10.186, 13.0628, 10, 12.5, 10);
    ctx.bezierCurveTo(11.6821, 10, 10.9559, 10.3928, 10.4998, 11);
    ctx.lineTo(10, 11);
    ctx.lineTo(10, 6);
    ctx.lineTo(12, 6);
    ctx.lineTo(14, 8.66667);
    ctx.closePath();
    ctx.fill('evenodd');
    ctx.stroke();
    ctx.restore();
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/svg';
    link.href = canvas.toDataURL();
    document.head.appendChild(link);
}
