<script lang="ts" setup>
import {toRefs} from 'vue';
import {useSettings} from '../settings';
import {useTranslates} from '../translates';
const S = useTranslates();
const {footer, colorTheme} = toRefs(useSettings());

function getFooter() {
    return footer?.value
        ?.replace(
            /([a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)/,
            '<a class="link" href="mailto:$1">$1</a>'
        )
        .replace(/https:\/\/\S+/g, '<a class="link" target="_blank" href="$&">$&</a>');
}
</script>
<template>
    <div class="footer">
        <div class="contacts" v-html="getFooter()"></div>
        <div class="copyright">{{ S.poweredBy }}</div>
    </div>
</template>
<style lang="less" scoped>
@import '../assets/variables.less';
.footer {
    padding: @padding / 2;
    .contacts {
        font-weight: 500;
        :deep(.link) {
            color: v-bind(colorTheme);
        }
    }
    .copyright {
        opacity: 0.4;
    }
}
</style>
