<script lang="ts" setup>
import {Descriptions, DescriptionsItem} from 'ant-design-vue';
import {toRefs} from 'vue';
import {OrderStatus} from '../interfaces';
import {useSettings} from '../settings';
import {useTranslates} from '../translates';
import dayjs from 'dayjs';

const {order, timeWindowSize} = toRefs(useSettings());
const S = useTranslates();
</script>
<template>
    <span
        v-if="order && !(order.state == OrderStatus.Scheduled && !order.plannedArrivalTime)"
        class="order-description"
    >
        <span v-if="order.state == OrderStatus.Processing">
            {{ S.processing }}
        </span>
        <Descriptions
            v-else-if="order.state == OrderStatus.Scheduled"
            layout="vertical"
            :colon="false"
            size="small"
            :column="2"
        >
            <DescriptionsItem :label="S.date">
                {{ dayjs(order.plannedArrivalTime).format('L') }}
            </DescriptionsItem>
            <DescriptionsItem :label="S.timeWindow">
                {{ dayjs(order.plannedArrivalTime).add(-timeWindowSize, 'seconds').format('LT') }} -
                {{ dayjs(order.plannedArrivalTime).add(timeWindowSize, 'seconds').format('LT') }}
            </DescriptionsItem>
        </Descriptions>
        <Descriptions v-else layout="vertical" :colon="false" size="small" :column="1">
            <DescriptionsItem :label="S.eta">
                {{
                    !order?.trackingDetails?.eta || order?.trackingDetails?.eta == null
                        ? S.noEtaMessage
                        : dayjs(order?.trackingDetails?.eta).format('L LT')
                }}
            </DescriptionsItem>
        </Descriptions>
    </span>
</template>
<style lang="less" scoped>
.order-description {
    :deep(.ant-descriptions-item) {
        padding-bottom: 0;
    }
    :deep(.ant-descriptions-item-label) {
        color: rgba(0, 0, 0, 0.4);
    }
}
</style>
