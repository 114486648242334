<script lang="ts" setup>
import {Map, MapBrowserEvent, View} from 'ol';
import {MouseWheelZoom, PinchZoom, DragPan, DoubleClickZoom} from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import {fromLonLat} from 'ol/proj';
import OSM from 'ol/source/OSM';
import {onBeforeUnmount, onMounted, ref, Ref, watch} from 'vue';
let map: Map;
const mapContainer: Ref<HTMLElement | null> = ref(null);
defineProps({
    minWidth: {
        type: Number,
        default: 0
    },
    minHeight: {
        type: Number,
        default: 0
    }
});
const emit = defineEmits(['ready']);
watch(mapContainer, (container: HTMLElement | null) => {
    if (container) {
        map = new Map({
            target: container,
            controls: [],
            interactions: [
                new MouseWheelZoom(),
                new PinchZoom(),
                new DoubleClickZoom(),
                new DragPan({
                    condition: (ev: MapBrowserEvent<TouchEvent>) =>
                        (ev.originalEvent.touches && ev.originalEvent.touches.length === 2) || !ev.originalEvent.touches
                })
            ],
            layers: [
                new TileLayer({
                    className: 'greyscale',
                    source: new OSM({
                        attributions: ''
                    })
                })
            ],
            view: new View({
                center: [0, 0],
                zoom: 2,
                extent: [...fromLonLat([-180, -80]), ...fromLonLat([180, 80])]
            })
        });
        emit('ready', map);
        setTimeout(() => {
            map.updateSize();
        }, 1000);
    }
});

function onResize() {
    if (map) {
        map.updateSize();
    }
}

onMounted(() => {
    window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize);
    map.dispose();
});
</script>
<template>
    <div class="w-full h-full relative" :style="{minWidth: minWidth + 'px', minHeight: minHeight + 'px'}">
        <div ref="mapContainer" class="map-container"></div>
    </div>
</template>
<style lang="less">
.greyscale {
    filter: hue-rotate(90deg) grayscale(95%);
}
</style>
<style lang="less" scoped>
.map-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    :deep(.ol-viewport) {
        border-radius: 12px;
    }
}
</style>
