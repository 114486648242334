<script lang="ts" setup>
import {Collapse, CollapsePanel} from 'ant-design-vue';
import {Ref, ref} from 'vue';

const props = defineProps({
    header: String,
    collapsible: Boolean,
    collapsed: {
        default: true,
        type: Boolean
    }
});

const isOpened: Ref<number> = ref(props.collapsed ? 0 : 1);
</script>
<template>
    <div class="panel">
        <Collapse
            :active-key="isOpened"
            :accordion="false"
            v-if="collapsible"
            :destroyInactivePanel="true"
            :bordered="false"
            expand-icon-position="right"
            @change="isOpened = isOpened ? 0 : 1"
        >
            <CollapsePanel :key="1" :header="header">
                <slot></slot>
            </CollapsePanel>
        </Collapse>
        <div v-else class="panel-plain">
            <slot></slot>
        </div>
    </div>
</template>
<style lang="less" scoped>
@import '../assets/variables.less';
.panel {
    &-plain {
        &:empty {
            display: none;
        }
        margin: @margin / 2 0;
        background-color: @panelBgColor;
        padding: @padding;
        border-radius: @border-radius;
        color: @textColor;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    }
    :deep(.ant-collapse) {
        padding: @padding;
        border-radius: @border-radius;
        color: @textColor;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        margin: @margin / 2 0;
        .ant-collapse-item {
            border: none;
            .ant-collapse-header {
                font-weight: 500;
                padding: 0;
            }
            .ant-collapse-content {
                border: none;
                padding: 0;
                &-box {
                    padding: @padding 0 0;
                }
            }
        }
    }
}
</style>
