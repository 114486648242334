<script lang="ts" setup>
import {Button, Layout, Spin, Textarea} from 'ant-design-vue';
import {Ref, computed, onBeforeMount, onBeforeUnmount, onMounted, provide, reactive, ref, toRefs} from 'vue';
import Footer from './components/Footer.vue';
import Header from './components/Header.vue';
import OrderDetails from './components/OrderDetails.vue';
import OrderInfo from './components/OrderInfo.vue';
import OrderProgress from './components/OrderProgress.vue';
import OrderRate from './components/OrderRate.vue';
import Overlay from './components/Overlay.vue';
import Panel from './components/Panel.vue';
import {SHOW_FOOTER} from './const';
import {OrderStatus, Viewport, ViewportSize} from './interfaces';
import {useSettings, setTitle, setFavicon} from './settings';
import {useTranslates} from './translates';
import {useTrackingService} from './tracking.service';
import thankYouMarker from './assets/images/thank-you.svg?raw';
const S = useTranslates();

const {completionOptions, colorTheme, order, trackingId, deliveryOptions} = toRefs(useSettings());
const trackingService = useTrackingService();

console.log(completionOptions.value);

const viewport: Viewport = reactive({
    height: 0,
    width: 0,
    size: ViewportSize.Medium
});
provide('viewport', viewport);

onBeforeMount(() => {
    window.addEventListener('resize', updateBrowserInfo);
});

onMounted(() => {
    updateBrowserInfo();
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateBrowserInfo);
});

function updateBrowserInfo() {
    viewport.height = window.innerHeight;
    viewport.width = window.innerWidth;
    if (viewport.width < 640) {
        viewport.size = ViewportSize.Small;
    } else if (viewport.width < 1000) {
        viewport.size = ViewportSize.Medium;
    } else {
        viewport.size = ViewportSize.Large;
    }
}

//For Feedback
const showFeedbackForm = ref(false); //computed(() => order.value?.state == OrderStatus.Completed);
const showSubmittedSuccessfull = ref(false);
const rating: Ref<number> = ref(0);
const comment: Ref<string | undefined> = ref('');
const svgThankYou: string = btoa(thankYouMarker.replace('#color#', colorTheme?.value || '#000'));

setTitle(S.appTitle);
setFavicon(colorTheme?.value || '#000');

async function saveFeedback() {
    if (order.value !== null && trackingId.value !== null) {
        await trackingService.sendRate(rating.value, comment.value || '', trackingId.value);
        order.value.rating.comment = comment.value;
        order.value.rating.rating = rating.value;
        showSubmittedSuccessfull.value = true;
    }
}

function rateExperience() {
    if (order.value != null && order.value.rating.rating == 0) {
        rating.value = order.value.rating.rating;
        comment.value = order.value.rating.comment;
        showFeedbackForm.value = true;
    }
}

function onRate(rate: number) {
    rating.value = rate;
}
</script>
<template>
    <Layout class="h-full main">
        <!-- <LayoutHeader style="padding: 0 12px; background-color: #f0f2f5">
            <Select v-model:value="state" :options="options" style="width: 100%" />
        </LayoutHeader> -->
        <Header v-if="order" />
        <div v-if="!order" class="loading">
            <Spin size="large" />
        </div>
        <div v-else-if="order.state === OrderStatus.NotFound">
            <div class="block-center h-full">
                <img src="./assets/images/not-found.svg" />
                <div class="title">{{ S.orderNotFound }}</div>
                <div class="description">{{ S.orderNotFoundDescription }}</div>
            </div>
        </div>
        <div v-else class="main-container">
            <div class="info-container">
                <Panel
                    v-if="
                        (order.state == OrderStatus.InProgress && deliveryOptions.useEta) ||
                        order.state == OrderStatus.Scheduled ||
                        order.state == OrderStatus.Processing
                    "
                >
                    <OrderInfo />
                </Panel>
                <Panel
                    v-else-if="order.state == OrderStatus.Completed && completionOptions.useRating"
                    @click="rateExperience"
                >
                    <OrderRate :disable="true" />
                </Panel>

                <Panel :collapsed="order.state != OrderStatus.InProgress" :collapsible="true" :header="S.orderDetails">
                    <OrderDetails />
                </Panel>
            </div>
            <div class="progress-container">
                <Panel :collapsible="true" :collapsed="false" :header="S.progress">
                    <OrderProgress />
                </Panel>
            </div>
        </div>
        <Overlay :title="S.feedback" :visible="showFeedbackForm" @close="showFeedbackForm = false" :backButton="true">
            <div class="h-full main">
                <Panel>
                    <OrderRate @onRate="onRate" :disable="false" />
                </Panel>
                <Panel>
                    <Textarea
                        :bordered="false"
                        :rows="7"
                        :placeholder="S.addComment"
                        v-model:value="comment"
                        :maxlength="255"
                    ></Textarea>
                </Panel>
                <div class="absolute bottom-0 left-0 w-full px-3 py-3 sm:relative sm:px-0 sm:py-0">
                    <Button :disabled="rating == 0" style="width: 100%" @click="saveFeedback" class="ant-btn-success">
                        {{ S.submit }}
                    </Button>
                </div>
            </div>
        </Overlay>
        <Overlay :title="S.feedback" :visible="showSubmittedSuccessfull" :backButton="false">
            <div class="block-center h-5/6">
                <img :src="'data:image/svg+xml;base64,' + svgThankYou" />
                <div class="title">{{ S.thankYou }}</div>
                <div class="description">{{ S.feedbackSent }}</div>
                <Button
                    @click="
                        showFeedbackForm = false;
                        showSubmittedSuccessfull = false;
                    "
                    class="back-to-order"
                    type="link"
                >
                    {{ S.backToOrder }}
                </Button>
            </div>
        </Overlay>
        <div class="h-full"></div>
        <Footer v-if="SHOW_FOOTER" />
    </Layout>
</template>
<style lang="less" scoped>
@import './assets/variables.less';

.ant-btn-success {
    height: 43px;
    background-color: v-bind(colorTheme);
    border: v-bind(colorTheme);
    color: white;
    &:hover {
        background-color: v-bind(colorTheme);
        opacity: 0.8;
        color: white;
    }
}
.back-to-order {
    color: v-bind(colorTheme);
    font-weight: 500;
    margin: 6px;
    &:hover {
        color: v-bind(colorTheme);
    }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    height: 43px;
}

.main {
    padding: @padding;
    min-height: 500px;
    height: max-content;
    @media (min-width: 1000px) {
        max-width: 960px;
        margin: 0 auto;
    }
    :deep(.overlay) {
        .header {
            @media (min-width: 1000px) {
                max-width: 960px;
                margin: 0 auto;
            }
        }
    }
}
.main-container {
    display: flex;
    flex-direction: column;
    @media (min-width: 1000px) {
        flex-direction: row;
        gap: @padding;
    }
    .info-container {
        flex: 1;
    }
    .progress-container {
        flex: 1;
    }
}
.block-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        font-size: 20px;
        font-weight: 500;
        color: @text-color;
        margin: @margin;
    }
    .description {
        font-size: 16px;
        color: @text-color;
        margin: 0 @margin* 2;
        opacity: 0.4;
    }
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 20px;
    font-weight: 500;
    color: @text-color;
}
</style>
