<script lang="ts" setup>
import {CheckCircleFilled, InfoCircleOutlined} from '@ant-design/icons-vue';
import {Timeline} from 'ant-design-vue';
import {inject, toRefs} from 'vue';
import {OrderStatus, Viewport} from '../interfaces';
import {useSettings} from '../settings';
import {useTranslates} from '../translates';
const S = useTranslates();
const viewport = inject<Viewport>('viewport');
const {colorTheme, order} = toRefs(useSettings());
</script>
<template>
    <div class="order-progress">
        <Timeline v-if="order">
            <Timeline.Item :class="{success: order.state >= OrderStatus.Processing}">
                <template #dot>
                    <CheckCircleFilled v-if="order.state >= OrderStatus.Processing" />
                    <span class="circle" v-else></span>
                </template>
                {{ S.state[OrderStatus.Processing] }}
            </Timeline.Item>
            <Timeline.Item :class="{success: order.state >= OrderStatus.Scheduled}">
                <template #dot>
                    <CheckCircleFilled v-if="order.state >= OrderStatus.Scheduled" />
                    <span class="circle" v-else></span>
                </template>
                {{ S.state[OrderStatus.Scheduled] }}
            </Timeline.Item>
            <Timeline.Item :class="{success: order.state >= OrderStatus.InProgress}">
                <template #dot>
                    <CheckCircleFilled v-if="order.state >= OrderStatus.InProgress" />
                    <span class="circle" v-else></span>
                </template>
                {{ S.state[OrderStatus.InProgress] }}
            </Timeline.Item>
            <Timeline.Item
                v-if="order.state != OrderStatus.Cancelled"
                :class="{success: order.state == OrderStatus.Completed}"
            >
                <template #dot>
                    <CheckCircleFilled v-if="order.state == OrderStatus.Completed" />
                    <span class="circle" v-else></span>
                </template>
                {{ S.state[OrderStatus.Completed] }}
            </Timeline.Item>
            <Timeline.Item v-else :class="{warn: order.state == OrderStatus.Cancelled}">
                <template #dot>
                    <InfoCircleOutlined v-if="order.state == OrderStatus.Cancelled" />
                    <span class="circle" v-else></span>
                </template>
                {{ S.state[OrderStatus.Cancelled] }}
            </Timeline.Item>
        </Timeline>
    </div>
</template>
<style lang="less" scoped>
@import '../assets/variables.less';
@inProgressColor: #000000;
.order-progress {
    margin-top: @margin;
    margin-left: @margin / 2;
    font-weight: 500;
    .circle {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #fff;
        border: 2px solid @inProgressColor;
        position: absolute;
        top: -7px;
        left: -7px;
    }
    :deep(.ant-timeline) {
        .ant-timeline-item {
            padding-bottom: @padding / 4;
            .ant-timeline-item-content {
                min-height: inherit;
            }
            &.ant-timeline-item-last {
                padding-bottom: 0;
            }
            .ant-timeline-item-tail {
                border-color: @inProgressColor;
            }
            .ant-timeline-item-head {
                background-color: transparent;
                color: @inProgressColor;
                border-color: @inProgressColor;
                border-width: 2px;
            }
            &.success {
                .ant-timeline-item-tail {
                    border-color: v-bind(colorTheme);
                }
                .ant-timeline-item-head,
                .ant-timeline-item-content {
                    color: v-bind(colorTheme);
                }
            }
            &.warn {
                .ant-timeline-item-tail {
                    border-color: @warnColor;
                }
                .ant-timeline-item-head,
                .ant-timeline-item-content {
                    color: @warnColor;
                }
                .anticon.anticon-info-circle {
                    border-radius: 50%;
                    background-color: #fff;
                }
            }
        }
    }
}
</style>
