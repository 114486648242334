import {isEqual} from 'lodash';
import {Order, OrderStatus, OrderTrackingInfo, OrderType, StatusMapping} from './interfaces';
import dayjs from 'dayjs';
export class TrackingService {
    private lastInfo: OrderTrackingInfo | null = null;
    private server: string = '';

    public subscribeToTrackingInfoUpdate(server: string, trackingId: string, updateCallback: (order: Order) => void) {
        const orderDetails = new EventSource(`${server}/api/tracking/${trackingId}/subscribe`);
        orderDetails.onerror = (e: Event) => console.error(e); //Modal.error({title: 'Error', content: 'Error connecting to server'});
        orderDetails.addEventListener('OrderTrackingInfo Event', (e: MessageEvent) => {
            console.info('Received updated order', e);
            const orderTrackingInfo: OrderTrackingInfo = JSON.parse(e.data);
            // compare with last info to see if we need to update
            if (!isEqual(orderTrackingInfo, this.lastInfo)) {
                this.lastInfo = orderTrackingInfo;
                updateCallback(this.mapRecordToOrder(orderTrackingInfo));
            }
        });
    }

    public async getTrackingInfo(server: string, trackingId: string): Promise<Order | null> {
        this.server = server;
        let orderTrackingInfo: OrderTrackingInfo | null = null;
        try {
            const response: Response = await fetch(`${server}/api/tracking/${trackingId}`);
            if (response.status == 200) {
                orderTrackingInfo = await response.json();
            }
        } catch (e) {
            console.error(e);
            orderTrackingInfo = null;
        }
        if (orderTrackingInfo) {
            this.lastInfo = orderTrackingInfo;
            return this.mapRecordToOrder(orderTrackingInfo);
        }
        return null;
    }

    private mapRecordToOrder(orderTrackingInfo: OrderTrackingInfo): Order {
        let types: {[name: string]: string} = {};
        try {
            if (attributeTypes) {
                types = attributeTypes;
            }
        } catch (e) {}

        let additionalAttributes: any = {};
        if (orderTrackingInfo.additionalAttributes) {
            Object.keys(orderTrackingInfo.additionalAttributes).forEach((key) => {
                const type = types[key] || 'STRING';
                let value = orderTrackingInfo.additionalAttributes?.[key];
                if (type === 'DATETIME' && value) {
                    value = dayjs(parseInt(value)).format('L LT');
                }
                additionalAttributes[key] = value;
            });
        }

        return {
            state: orderTrackingInfo.status ? StatusMapping[orderTrackingInfo.status] : OrderStatus.Processing,
            id: orderTrackingInfo.orderReference,
            location: {
                postcode: orderTrackingInfo.postcode,
                address: orderTrackingInfo.address,
                latitude: orderTrackingInfo.location?.latitude,
                longitude: orderTrackingInfo.location?.longitude
            },
            weight: undefined, //Server can't return this
            volume: undefined, //Server can't return this
            customer: {
                name: orderTrackingInfo.customerName,
                phone: undefined //Server can't return this
            },
            plannedArrivalTime: orderTrackingInfo.plannedArrivalTime,
            // timeWindowStart: orderTrackingInfo.timeWindowStart,
            // timeWindowEnd: orderTrackingInfo.timeWindowEnd,
            date: undefined, //Server can't return this
            type: orderTrackingInfo.type
                ? orderTrackingInfo.type == 'DELIVERY'
                    ? OrderType.Delivery
                    : OrderType.Pickup
                : undefined,
            title: orderTrackingInfo.title,
            trackingDetails: {
                eta: orderTrackingInfo.trackingDetails?.eta,
                stop: orderTrackingInfo.trackingDetails?.stop,
                currentStop: orderTrackingInfo.trackingDetails?.currentStop,
                totalStops: orderTrackingInfo.trackingDetails?.totalStops,
                driverLocation: orderTrackingInfo.trackingDetails?.driverLocation,
                cancellationReason: orderTrackingInfo.trackingDetails?.cancellationReason
            },
            rating: {
                comment: orderTrackingInfo.rating?.comment || '',
                rating: orderTrackingInfo.rating?.rating || 0
            },
            images: orderTrackingInfo.attachments || [],
            additionalAttributes
        };
    }

    async sendRate(rating: number, comment: string, trackingId: string): Promise<void> {
        if (this.lastInfo) {
            await fetch(`${this.server}/api/tracking/${trackingId}/rate`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    rating,
                    comment
                })
            });
        }
    }
}

const trackingService = new TrackingService();

export function useTrackingService() {
    return trackingService;
}
