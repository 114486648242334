<script lang="ts" setup>
import {RightOutlined} from '@ant-design/icons-vue';
import {Rate} from 'ant-design-vue';
import {Ref, ref, watchEffect} from 'vue';
import {useSettings} from '../settings';
import {useTranslates} from '../translates';
const S = useTranslates();
const {colorTheme, order} = useSettings();
const rate: Ref<number> = ref(0);

const emit = defineEmits(['onRate']);

defineProps({
    disable: Boolean
});

watchEffect(() => {
    emit('onRate', rate.value);
});
</script>
<template>
    <div class="order-rate" :class="{'cursor-pointer': order?.rating?.rating == 0}" v-if="disable && order">
        <div class="ant-collapse-header">
            <div class="text-xl outlined-rating" v-if="order?.rating?.rating == 0">
                <div class="mb-4">
                    {{ S.pleaseRateExperience }}
                </div>
                <Rate v-model:value="order.rating.rating" :disabled="true"></Rate>
            </div>
            <div class="text-xl" v-else>{{ S.yourFeedback }}</div>
            <RightOutlined class="ant-collapse-arrow" v-if="order?.rating?.rating == 0" />
        </div>
        <Rate v-model:value="order.rating.rating" :disabled="true" v-if="order?.rating?.rating"></Rate>
        <div style="word-break: break-word">{{ order?.rating?.comment }}</div>
    </div>
    <div class="order-rate" v-if="!disable">
        <div class="text-xl mb-2">{{ S.rateExperience }}</div>
        <Rate v-model:value="rate"></Rate>
    </div>
</template>
<style lang="less" scoped>
@import '../assets/variables.less';
.ant-collapse-header {
    font-weight: 500;
    padding: 0;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    transition: all 0.3s, visibility 0s;
}

.ant-collapse-arrow {
    position: absolute;
    top: 50%;
    right: 12px;
    left: auto;
    margin: 0;
    transform: translateY(-50%);
    display: inline-block;
    font-size: 12px;
    vertical-align: -1px;
}
.order-rate {
    background-color: @panelBgColor;
    :deep(.ant-rate) {
        color: v-bind(colorTheme);
        font-size: 36px;
    }
}
</style>
