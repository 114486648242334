import {App as VueApp, createApp} from 'vue';
import App from './App.vue';
import './assets/styles.less';
import {useSettings} from './settings';
import {setLanguage} from './translates';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/ru';
import 'dayjs/locale/pt';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
dayjs.extend(localizedFormat);
dayjs.locale(window.navigator.language);
const observer = new ResizeObserver((resizedItems) => {
    const resizedItem = resizedItems[0];
    const target = resizedItem.target as HTMLElement;
    if (target === document.body) {
        window.parent.postMessage({name: 'tw-resize', rect: resizedItem.contentRect}, '*');
    }
});
observer.observe(document.body, {
    box: 'border-box'
});

const app: VueApp = createApp(App);
const {language} = useSettings();
setLanguage(language);

app.mount('body');
