interface Point {
    latitude?: number;
    longitude?: number;
}

interface OrderTrackingDetails {
    eta?: string;
    stop?: number;
    currentStop?: number;
    totalStops?: number;
    driverLocation?: {
        latitude: number;
        longitude: number;
    };
    cancellationReason?: string;
}

interface RateOrder {
    comment?: string;
    rating?: number;
}

export interface OrderTrackingInfo {
    status?: 'PROCESSING' | 'SCHEDULED' | 'ON_WAY' | 'DELIVERED' | 'FAILED';
    address?: string;
    location?: Point;
    plannedArrivalTime?: string;
    timeWindowStart?: string;
    timeWindowEnd?: string;
    postcode?: string;
    orderReference?: string;
    customerName?: string;
    measure1Name?: string;
    measure2Name?: string;
    measure1?: number;
    measure2?: number;
    type?: 'DELIVERY' | 'COLLECTION';
    trackingDetails?: OrderTrackingDetails;
    rating?: RateOrder;
    title?: string;
    additionalAttributes?: {[key: string]: string};
    attachments?: {thumbnailUri: string; fullUri: string}[];
}

export interface Order {
    status?: string;
    id?: string;
    title?: string;
    location: {
        postcode?: string;
        address?: string;
        latitude?: number;
        longitude?: number;
    };
    weight?: number;
    volume?: number;
    customer: {
        name?: string;
        phone?: string;
    };
    plannedArrivalTime?: string;
    timeWindowStart?: string;
    timeWindowEnd?: string;
    date?: string;
    type?: OrderType;
    trackingDetails?: {
        eta?: string | null;
        stop?: number;
        currentStop?: number;
        totalStops?: number;
        driverLocation?: {
            latitude: number;
            longitude: number;
        };
        cancellationReason?: string;
    };
    state: OrderStatus;
    images?: {thumbnailUri: string; fullUri: string}[];
    rating: {
        comment?: string;
        rating?: number;
    };
    additionalAttributes?: any;
}
export enum OrderType {
    Delivery = 'delivery',
    Pickup = 'pickup'
}

export enum OrderStatus {
    NotFound = 0,
    Processing = 1,
    Scheduled = 2,
    InProgress = 3,
    Completed = 4,
    Cancelled = 5
}

export const StatusMapping = {
    PROCESSING: OrderStatus.Processing,
    SCHEDULED: OrderStatus.Scheduled,
    ON_WAY: OrderStatus.InProgress,
    DELIVERED: OrderStatus.Completed,
    FAILED: OrderStatus.Cancelled
};

export enum ViewportSize {
    Small = 'sm',
    Medium = 'md',
    Large = 'lg'
}

export interface Viewport {
    width: number;
    height: number;
    size: ViewportSize;
}

// {
//     "status": "PROCESSING",
//     "address": "string",
//     "location": {
//       "latitude": 0,
//       "longitude": 0
//     },
//     "timeWindowStart": "2023-02-01T09:03:59.887Z",
//     "timeWindowEnd": "2023-02-01T09:03:59.887Z",
//     "postcode": "string",
//     "orderReference": "string",
//     "customerName": "string",
//     "measure1Name": "string",
//     "measure2Name": "string",
//     "measure1": 0,
//     "measure2": 0,
//     "type": "DELIVERY",
//     "trackingDetails": {
//       "eta": "2023-02-01T09:03:59.887Z",
//       "stop": 0,
//       "currentStop": 0,
//       "totalStops": 0,
//       "driverLocation": {
//         "latitude": 0,
//         "longitude": 0
//       }
//     },
//     "rating": {
//       "comment": "string",
//       "rating": 5
//     }
//   }
