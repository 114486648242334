import {isArray} from 'lodash';
import strings from './strings.json';
declare type TStrings = {[key in keyof typeof strings]: string} | any;

var language: string = 'en';
let overrides: any = {};
let translates: any = {};
export function setLanguage(lang: string = 'en') {
    language = lang;
    updateTranslates();
}

try {
    if (widgetTranslation) {
        setOverrides(widgetTranslation);
    }
} catch (e) {}

export function setOverrides(data: any) {
    overrides = {};
    Object.keys(data).forEach((key) => {
        const langs: string[] = Object.keys(data[key]);
        overrides[key] = data[key];
        if (langs.length > 0) {
            if (langs.indexOf('en') == -1) {
                overrides[key].en = data[key][langs[0]];
            }
        }
    });
    updateTranslates();
}

function updateTranslates(): void {
    const data = Object.assign({}, strings, overrides);
    Object.assign(
        translates,
        Object.keys(data).reduce((acc: {[index: string]: string}, key: string) => {
            if (data.hasOwnProperty(key)) {
                if (isArray((data as any)[key])) {
                    acc[key] = (data as any)[key].map((item: any) => item[language] || item.en);
                } else {
                    acc[key] = (data as any)[key][language] || (data as any)[key].en;
                }
            } else {
                acc[key] = key;
            }
            return acc;
        }, {}) as TStrings
    );
}

export function useTranslates(): TStrings {
    return translates;
}
