<script lang="ts" setup>
import {Descriptions, Image, ImagePreviewGroup, Row} from 'ant-design-vue';
import {Feature, Map} from 'ol';
import {Point} from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import {fromLonLat} from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import {Ref, inject, onMounted, ref, shallowRef, toRefs, watch} from 'vue';
import orderMarker from '../assets/images/marker.svg?raw';
import vehicleMarker from '../assets/images/vehicle.svg?raw';
import {OrderStatus, Viewport, ViewportSize} from '../interfaces';
import {useSettings} from '../settings';
import {useTranslates} from '../translates';
import OpenlayerMap from './OpenlayerMap.vue';
import dayjs from 'dayjs';
import {extend, getCenter} from 'ol/extent';

const S = useTranslates();
const viewport = inject<Viewport>('viewport');
const showPreview = ref(false);
const galleryContainer: Ref<HTMLElement | null> = ref(null);
const galleryCount = ref(0);
const {completionOptions, colorTheme, order} = toRefs(useSettings());
const map = shallowRef<Map | null>(null);

const orderLayer = new VectorLayer({
    source: new VectorSource(),
    style: () => {
        return new Style({
            image: new Icon({
                src: 'data:image/svg+xml;base64,' + btoa(orderMarker.replace('#color#', colorTheme?.value || '#000'))
            })
        });
    }
});
const vehicleLayer: VectorLayer<VectorSource> = new VectorLayer({
    source: new VectorSource(),
    style: () => {
        return new Style({
            image: new Icon({
                src: 'data:image/svg+xml;base64,' + btoa(vehicleMarker.replace('#color#', colorTheme?.value || '#000')),
                rotation:
                    orderLayer.getSource() && vehicleLayer.getSource()
                        ? Math.atan2(
                              getCenter((orderLayer.getSource() as any).getExtent())[0] -
                                  getCenter((vehicleLayer.getSource() as any).getExtent())[0],
                              getCenter((orderLayer.getSource() as any).getExtent())[1] -
                                  getCenter((vehicleLayer.getSource() as any).getExtent())[1]
                          )
                        : 0
            })
        });
    }
});
onMounted(() => {
    watch(
        () => viewport?.width,
        () => {
            recalculateGalleryCount();
        },
        {
            immediate: true
        }
    );
    watch(
        [map, order],
        ([m, o]) => {
            if (m && o?.location && o.location.latitude && o.location.longitude) {
                const coordinates = fromLonLat([o.location.longitude, o.location.latitude]);
                let needToZoom = true;
                const features = orderLayer.getSource()?.getFeatures();
                if (features && features.length > 0) {
                    orderLayer.getSource()?.clear();
                    vehicleLayer.getSource()?.clear();
                    needToZoom = false;
                }
                orderLayer.getSource()?.addFeature(
                    new Feature({
                        geometry: new Point(coordinates)
                    })
                );
                if (o.state == OrderStatus.InProgress && o.trackingDetails?.driverLocation) {
                    vehicleLayer.getSource()?.addFeature(
                        new Feature({
                            geometry: new Point(
                                fromLonLat([
                                    o.trackingDetails.driverLocation.longitude,
                                    o.trackingDetails.driverLocation.latitude
                                ])
                            )
                        })
                    );
                }
                if (needToZoom) {
                    if (o.trackingDetails?.driverLocation && o.state == OrderStatus.InProgress) {
                        const extent = orderLayer.getSource()?.getExtent();
                        if (extent) {
                            extend(extent, vehicleLayer.getSource()?.getExtent() || []);
                            m.getView().fit(extent, {duration: 0, padding: [50, 50, 50, 50]});
                        }
                    } else {
                        m.getView().animate({center: coordinates, zoom: 16, duration: 0});
                    }
                }
            }
        },
        {
            immediate: true
        }
    );
});

watch(colorTheme, () => {
    orderLayer.changed();
});

function recalculateGalleryCount() {
    if (galleryContainer.value) {
        galleryCount.value = Math.floor(galleryContainer.value.offsetWidth / 100);
    }
}

function onMapReady(m: Map) {
    map.value = m;
    map.value.addLayer(orderLayer);
    map.value.addLayer(vehicleLayer);
}
</script>
<template>
    <div class="gallery" v-if="order?.images" ref="galleryContainer">
        <div class="hidden">
            <ImagePreviewGroup
                :preview="{visible: showPreview, onVisibleChange: (vis: boolean) => (showPreview = vis)}"
            >
                <Image v-for="(image, index) in order.images" :src="image.fullUri" />
            </ImagePreviewGroup>
        </div>
        <div v-for="(image, index) in order.images" class="gallery-item" @click="showPreview = !!image.fullUri">
            <Image :key="index" :src="image.thumbnailUri" :preview="{visible: false}" v-if="index < galleryCount" />
            <div class="more" v-if="index == galleryCount - 1 && order?.images.length > galleryCount">
                + {{ order?.images.length - galleryCount }}
            </div>
        </div>
    </div>
    <div class="map">
        <OpenlayerMap @ready="onMapReady" />
    </div>
    <Descriptions
        class="order-info"
        :column="{xs: 1, sm: 3, md: 3}"
        :layout="viewport?.size == ViewportSize.Small ? 'horizontal' : 'vertical'"
    >
        <Descriptions.Item v-if="order?.location?.address" :label="S.address" class="address" :span="3">
            {{ order?.location.address }}
        </Descriptions.Item>
        <!-- 
        <Descriptions.Item
            v-if="order?.trackingDetails?.driverLocation?.latitude && order?.trackingDetails?.driverLocation?.longitude"
            :label="S.deliveryLocation"
            class="address"
            :span="3"
        >
            {{ order?.trackingDetails.driverLocation.latitude }},
            {{ order?.trackingDetails.driverLocation.longitude }}
        </Descriptions.Item> -->

        <Descriptions.Item v-if="order?.trackingDetails?.eta" :label="S.eta">
            <span v-if="order?.state == OrderStatus.Completed">{{ S.completed }}</span>
            <span v-else>{{ dayjs(order?.trackingDetails?.eta).format('L LT') }}</span>
        </Descriptions.Item>
        <Descriptions.Item
            v-if="order?.trackingDetails?.stop && order?.trackingDetails?.totalStops"
            :label="S.stopNumber"
        >
            {{ order?.trackingDetails.stop }} {{ S.outOf }} {{ order?.trackingDetails.totalStops }}
        </Descriptions.Item>
        <Descriptions.Item v-if="order?.trackingDetails?.currentStop" :label="S.driverCurrentStop">
            {{ order?.trackingDetails.currentStop }}
        </Descriptions.Item>

        <Descriptions.Item v-if="order?.id" :label="S.reference">{{ order?.id }}</Descriptions.Item>
        <Descriptions.Item v-if="order?.location?.postcode" :label="S.postcode">
            {{ order?.location.postcode }}
        </Descriptions.Item>
        <Descriptions.Item v-if="order?.customer?.name" :label="S.customerName">
            {{ order?.customer.name }}
        </Descriptions.Item>
        <Descriptions.Item v-if="order?.weight" :label="S.weight">
            {{ order?.weight }} {{ S.weightUnit }}
        </Descriptions.Item>
        <Descriptions.Item v-if="order?.volume" :label="S.volume">
            {{ order?.volume }} {{ S.volumeUnit }}
        </Descriptions.Item>
        <Descriptions.Item v-if="order?.type" :label="S.type">{{ S[order?.type] }}</Descriptions.Item>
        <Descriptions.Item
            v-if="order?.additionalAttributes"
            v-for="(value, name) in order.additionalAttributes"
            :label="S['attr_' + name]"
        >
            {{ value }}
        </Descriptions.Item>
        <Descriptions.Item
            :label="S.reason"
            v-if="completionOptions.cancellationReason && order?.state == OrderStatus.Cancelled"
        >
            {{ order?.trackingDetails?.cancellationReason }}
        </Descriptions.Item>
    </Descriptions>
</template>
<style lang="less" scoped>
@import '../assets/variables.less';
.gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 @margin 0;
    .gallery-item {
        margin: 0 @margin @margin 0;
        position: relative;
        :deep(.ant-image-img) {
            width: 96px;
            height: 96px;
        }
        .more {
            position: absolute;
            z-index: 1;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.5);
            width: 96px;
            height: 96px;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
        }
    }
}
.map {
    height: 200px;
}
.order-info {
    :deep(.ant-descriptions-view) {
        margin-top: @margin;
        .ant-descriptions-row {
            .ant-descriptions-item {
                padding-bottom: 0;
                &.address {
                    .ant-descriptions-item-container {
                        flex-direction: column;
                    }
                }
                &-label {
                    opacity: 0.5;
                    flex: 1;
                }
            }
        }
    }
}
</style>
